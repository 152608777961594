import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import api from '../../Middleware/api/api';
import { useAuth } from '../../services/contexts';
import { useUser } from '../../services/contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import shadows from '@mui/material/styles/shadows';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import Box from '@mui/system/Box';
import IconButton from '@mui/material/IconButton';
import FileOpen from '@mui/icons-material/FileOpenOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { formatCurrency } from '../../services/utils/format-numbers';
const Feedback = lazy(() => import('../../components/DataTable/Feedback'));
import BillStatusLabel from '../../components/BillStatusLabel';
import LoadingButton from '../../components/core/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import {
  Divider as MuiDivider,
  Grid,
  Button,
  Stack,
  TableCell,
  Typography,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Checkbox,
} from '@mui/material';
import { spacing } from '@mui/system';
import LogoLoader from '../../components/LogoLoader';
import DataTable from '../../components/DataTable';
import LightTooltip from '../Users/components/CreateUser/LightTooltip';
import { Item } from './styles';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  PAGE_SIZE,
  BillingDataForOrigin,
} from '../../services/utils/units-constants';
import jwtDecode from 'jwt-decode';

const Divider = styled(MuiDivider)(spacing);
let rows = [];

// eslint-disable-next-line no-unused-vars

function getStartDate() {
  let date = new Date();
  let dayOfMonth = date.getDate();
  return calculatedates(date, dayOfMonth - 1);
}

function getEndDate() {
  let date = new Date();
  return calculatedates(date, 0);
}

function calculatedates(date, minusDays) {
  let last = new Date(date.getTime() - minusDays * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}

function OperatorBills() {
  const { t } = useTranslation();

  let initDataSuspenseFallbackMessage = {
    open: false,
    title: '',
    severity: '',
    txtMessage: '',
    fetchBillsForOperator: true,
    logic: '',
  };

  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPDF] = useState([]);
  const [loading, setLoading] = useState(false);
  const [operatorResponse] = useState(null);
  const [operatorRequestError] = useState(null);
  const [showBetreiberButton] = useState(true);
  const [BTNSubmitIsSubmitting, setBTNSubmitIsSubmitting] = useState(false);
  const [ButtonSendBillsIsSubmitting, setButtonSendBillsIsSubmitting] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  //standort states
  const { token } = useAuth();
  const { user } = useUser();
  const [bills, setBills] = useState([]);
  const [openTooltipId, setOpenTooltipId] = useState(null);
  const open = Boolean(anchorEl);
  const [
    ButtonRegenerateIncorrectBillsIsSubmitting,
    setButtonRegenerateIncorrectBillsIsSubmitting,
  ] = useState(false);

  const [dataSuspenseFallbackMessage, setDataSuspenseFallbackMessage] =
    useState(initDataSuspenseFallbackMessage);

  const handleIConClick = (billId) => {
    setOpenTooltipId(billId);
  };

  const handleTooltipClose = () => {
    setOpenTooltipId(null);
  };

  const handleTooltipOpen = (billId) => {
    setOpenTooltipId(billId);
  };

  const unselectAllRows = () => {
    setSelectedRows([]);
    setSelected([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (token && user) {
      fetchBillsForOperator(pageSize, currentPage);
    }
  }, [
    token,
    operatorResponse,
    operatorRequestError,
    showBetreiberButton,
    user,
    currentPage,
    pageSize,
  ]);

  const decodedToken = token ? jwtDecode(token) : null;

  const renderToHtml = (props) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: props,
        }}
      />
    );
  };

  const renderPaymentIntents = (props) => {
    return renderToHtml(
      showstripeSepaPaymentIntentIdAndStripeBillPaymentStatus(props)
    );
  };

  const getBillCollectionDate = (props) => {
    return props.billCollectionDate != null
      ? new Date(props.billCollectionDate).toLocaleDateString()
      : t('LBLBillNotYetSent');
  };
  const getStripePaymentIntent = (props) => {
    return props.stripeSepaPaymentIntentId != null
      ? props.stripeSepaPaymentIntentId
      : '';
  };
  const getStripePaymentStatus = (props) => {
    return props.stripeSepaBillPaymentStatus != null
      ? props.stripeSepaBillPaymentStatus
      : '';
  };

  function showstripeSepaPaymentIntentIdAndStripeBillPaymentStatus(props) {
    let billCollectionDateSection = '';
    billCollectionDateSection =
      t('LBLBillCollectionDate') + ' : ' + getBillCollectionDate(props);
    let paymentIntentSection = '';
    paymentIntentSection =
      '<br>' +
      t('stripeSepaPaymentIntentId') +
      ' : ' +
      getStripePaymentIntent(props);
    let paymentStatusSection = '';
    paymentStatusSection =
      '<br>' +
      t('stripeSepaPaymentStatus') +
      ' : ' +
      getStripePaymentStatus(props);
    return (
      billCollectionDateSection + paymentIntentSection + paymentStatusSection
    );
  }

  const columns = useMemo(
    () => [
      {
        name: 'billNumber',
        key: 'billNumberCheckbox',
        options: {
          display: false,
          filter: false,
          customBodyRender: (value) => {
            return value || <>-</>;
          },
        },
      },
      {
        name: 'billNumber',
        key: 'billNumber',
        label: t('billnum'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('billnum')}
            </TableCell>
          ),
          customBodyRender: (value) => (
            <Typography align="center">{value}</Typography>
          ),
        },
      },
      {
        name: 'endCustomerId',
        key: 'endCustomerId',
        label: t('LBLendCustomerId'),
        options: {
          filter: false,
          display: true,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLendCustomerId')}
            </TableCell>
          ),
          customBodyRender: (value) => (
            <Typography align="center">{value}</Typography>
          ),
        },
      },
      {
        name: 'fullName',
        key: 'fullName',
        label: t('LBLfullName'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                maxWidth: '10vw', // Adjust this percentage for responsiveness
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('LBLfullName')}
            </TableCell>
          ),
          customBodyRender: (value) => (
            <Tooltip title={value || ''} placement="top">
              <Typography
                align="left"
                sx={{
                  maxWidth: '10vw', // Matches header width, responsive to screen size
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </Typography>
            </Tooltip>
          ),
        },
      },
      {
        name: 'paymentMethod',
        key: 'paymentMethod',
        label: t('LBLpaymentMethod'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLpaymentMethod')}
            </TableCell>
          ),
          customBodyRender: (paymentMethod) => {
            return (
              <>
                <Typography align="left">
                  {paymentMethod === 'BANK_TRANSFER'
                    ? t('rechnung')
                    : paymentMethod === 'STRIPE_SEPA'
                      ? t('sepaStripemode')
                      : paymentMethod === 'SEPA'
                        ? t('LBLsepamode')
                        : paymentMethod}
                </Typography>
              </>
            );
          },
        },
      },
      {
        name: 'billingStatus',
        key: 'billingStatus',
        label: t('LBLbillingStatus'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLbillingStatus')}
            </TableCell>
          ),
          customBodyRender: (value) => {
            return (
              <BillStatusLabel
                textAlign={'center'}
                title={value?.toLowerCase()}
              />
            );
          },
        },
      },
      {
        name: 'billDownloadDate',
        key: 'billDownloadDate',
        label: t('downloadDate'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'right',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('downloadDate')}
            </TableCell>
          ),
          customBodyRender: (value) => (
            <Typography align="right">
              {value !== null
                ? format(new Date(value), 'dd.MM.yyyy, HH:mm') + ' Uhr'
                : ''}
            </Typography>
          ),
        },
      },
      {
        name: 'totalTaxedCosts',
        key: 'totalTaxedCosts',
        label: t('totalCost'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'right',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('totalCost')}
            </TableCell>
          ),
          customBodyRender: (value) => (
            <Typography align="right">{value}</Typography>
          ),
        },
      },
      {
        name: 'open',
        key: 'open',
        label: ' ',
        options: {
          filter: false,
          sort: false,
        },
      },

      ...(bills.some((bill) => bill.paymentMethod === 'STRIPE_SEPA')
        ? [
            {
              name: 'details',
              key: 'details',
              label: ' ',
              options: {
                filter: false,
                sort: false,
                customBodyRender(value) {
                  return (
                    <>
                      {value.paymentMethod !== 'STRIPE_SEPA' ? null : (
                        <>
                          <Item>
                            <Typography fontWeight="fontWeightMedium">
                              <InfoIcon
                                onClick={() => handleIConClick(value.billId)}
                                color="action"
                                fontSize="small"
                                cl="true"
                              />
                            </Typography>
                          </Item>

                          <LightTooltip
                            open={openTooltipId === value.billId}
                            onClose={handleTooltipClose}
                            placement="top-start"
                            title={renderPaymentIntents(value) || ''}
                            onOpen={() => handleTooltipOpen(value.billId)}
                          ></LightTooltip>
                        </>
                      )}
                    </>
                  );
                },
              },
            },
            {
              name: 'stripeSepaBillPaymentStatus',
              key: 'stripeSepaBillPaymentStatus',
              label: t('stripeSepaPaymentStatus'),
              options: {
                filter: false,
                display: false,
                search: true,
                customHeadRender: () => (
                  <TableCell
                    align="left"
                    variant="head"
                    sx={{
                      textAlign: 'right',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: 'white',
                    }}
                  >
                    {t('stripeSepaPaymentStatus')}
                  </TableCell>
                ),
                customBodyRender: (value) => (
                  <Typography align="right">{value}</Typography>
                ),
              },
            },
          ]
        : []),
    ],
    [bills, renderPaymentIntents]
  );

  const renderData = useMemo(() => {
    const rawbill = bills;

    return rawbill
      ?.sort(
        (entry1, entry2) =>
          entry2.billGenerationDate - entry1.billGenerationDate
      )
      .map((entry) => {
        const loading = loadingPDF.includes(entry.billNumber);

        const { billingStatus, totalTaxedCosts, paymentMethod, ...rawbill } =
          entry;

        const newEntry = {
          ...rawbill,
          details: entry,
          totalTaxedCosts:
            totalTaxedCosts !== null ? formatCurrency(totalTaxedCosts) : null,
          paymentMethod: paymentMethod,
          billingStatus: billingStatus,
          open:
            billingStatus !== null ? (
              <Box position="relative">
                <IconButton
                  data-bill-number={entry.billNumber}
                  disabled={loading}
                  aria-label={`open bill ${entry.billNumber}`}
                  href={`${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/pdf/${entry.billNumber}?verificationId=${entry.billId}`}
                  target="_blank"
                >
                  <FileOpen />
                </IconButton>
                {loading && (
                  <CircularProgress
                    size={38}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            ) : null,

          stripeSepaPaymentIntentId:
            entry.paymentMethod === 'STRIPE_SEPA'
              ? entry?.stripeSepaPaymentIntentId || ''
              : '',
          stripeSepaBillPaymentStatus:
            entry.paymentMethod === 'STRIPE_SEPA'
              ? entry?.stripeSepaBillPaymentStatus || ''
              : '',
        };

        return newEntry;
      });
  }, [bills, loadingPDF]);

  useEffect(() => {
    setSelected(selectedRows.map((index) => bills[index]));
  }, [selectedRows]);

  const dateFiltersInitialValues = {
    startDate: startDate,
    endDate: endDate,
  };

  const dateFiltersValidationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .label(t('LBLStartDate'))
          .required(t('startDateRequired')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .min(Yup.ref('startDate'), t('LBLENdgStart'))
          .label(t('LBLEndDate'))
          .required(t('LBLEndDateReq')),
      }),
    []
  );

  const handleSubmitGenerationDates = (values) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    fetchBillsForOperator(pageSize, currentPage);
  };

  const handleSendPdfBillEmail = (selected) => {
    sendPdfBillEmail(selected);
  };
  const handleDownloadPdfBills = (selected) => {
    downloadPdfBillsAsZip(selected);
  };
  const handleRegenerateBills = (selected) => {
    regenerateIncorrectBills(selected);
  };

  const handleStartDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
      console.error('Invalid date input');
    }
  };

  const handleEndDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
      console.error('Invalid EndDate input');
    }
  };

  const showResponseAfterSendingBill = (
    showSuccessFeedback,
    showErrorFeedback
  ) => {
    setButtonSendBillsIsSubmitting(false);
    unselectAllRows();
    if (showSuccessFeedback) {
      let successData = {
        open: true,
        title: t('LBLSendBillsPdfMailSuccess'),
        severity: 'success',
        txtMessage: t('MSGSendBillsPdfMailSuccess'),
        fetchBillsForOperator: true,
        logic: 'SendingBillSuccess',
      };
      setDataSuspenseFallbackMessage(successData);
    }
    if (showErrorFeedback) {
      let errorData = {
        open: true,
        title: t('LBLSendBillsPdfMailError'),
        severity: 'warning',
        txtMessage: t('MSGSendBillsPdfMailError'),
        fetchBillsForOperator: true,
        logic: 'SendingBillError',
      };
      setDataSuspenseFallbackMessage(errorData);
    }
  };

  const sendPdfBillEmail = (selected) => {
    if (token && user) {
      try {
        setLoading(true);
        setButtonSendBillsIsSubmitting(true);
        unselectAllRows();
        api.sendBillsPdfEmail(token, selected).then(
          (response) => {
            setLoading(false);
            showResponseAfterSendingBill(true, false);
          },
          () => {
            setLoading(false);
            showResponseAfterSendingBill(false, true);
          }
        );
      } catch (exception) {
        setLoading(false);
        showResponseAfterSendingBill(false, true);
      }
    }
  };

  const downloadPdfBillsAsZip = async (selected) => {
    if (token && user) {
      try {
        setLoading(true);
        unselectAllRows();

        // Transform the selected bills array to get billId and billNumber
        const billPdfDtoList = selected.map((bill) => ({
          billId: bill.billId,
          billNumber: bill.billNumber,
        }));

        const requestBody = {
          billPdfDtoList: billPdfDtoList,
        };

        // Making the API call to download the ZIP file
        const response = await api.downloadBills(token, requestBody);

        if (response.status === 200) {
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'Rechnungen.zip'; // Default filename if none is provided

          if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition
              .split('filename=')[1]
              .replace(/['"]/g, ''); // Extract the filename and remove quotes
          }

          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: 'application/zip' });

          // Create a link element to trigger download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename; // Set the filename from the response header
          document.body.appendChild(link);
          link.click(); // Trigger download
          document.body.removeChild(link); // Clean up after download

          setLoading(false);
          fetchBillsForOperator(pageSize, currentPage);
        } else {
          console.error('Error downloading file:', response.statusText);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error during file download:', error);
      }
    }
  };

  // attention, this handlings support only one bill response
  const showSuccessResponseAfterRegeneratingBills = (response) => {
    setButtonRegenerateIncorrectBillsIsSubmitting(false);
    unselectAllRows();
    let successfulBillRegenerations_length =
      response.data.data.successfulBillRegenerations.length;
    let unSuccessfulBillRegenerations_length =
      response.data.data.unSuccessfulBillRegenerations.length;
    if (
      successfulBillRegenerations_length === 0 &&
      unSuccessfulBillRegenerations_length === 0
    ) {
      let warningData = {
        open: true,
        title: t('LBLTitleRegenerateBillsWarning'),
        severity: 'warning',
        txtMessage: t('MSGRegenerateBillsNoBillWasRegenerated'),
        fetchBillsForOperator: true,
        logic: 'RegenerateBillsWarning',
      };
      setDataSuspenseFallbackMessage(warningData);
    } else if (
      successfulBillRegenerations_length === 0 &&
      unSuccessfulBillRegenerations_length > 0
    ) {
      let warningData = {
        open: true,
        title: t('LBLTitleRegenerateBillsError'),
        severity: 'warning',
        txtMessage: t('MSGRegenerateBillsBillsNotRegenerated'),
        fetchBillsForOperator: true,
        logic: 'RegenerateBillsWarning',
      };
      setDataSuspenseFallbackMessage(warningData);
    } else if (
      successfulBillRegenerations_length > 0 &&
      unSuccessfulBillRegenerations_length > 0
    ) {
      let warningData = {
        open: true,
        title: t('LBLTitleRegenerateBillsWarning'),
        severity: 'warning',
        txtMessage: renderToHtml(
          t('MSGRegenerateBillsBillsRegenerated') +
            ' <br> ' +
            response.data.data.successfulBillRegenerations
              .map((item) => item.billNumber)
              .join(' <br> ') +
            ' <br> ' +
            t('MSGRegenerateBillsBillsNotRegenerated') +
            ' <br> ' +
            response.data.data.unSuccessfulBillRegenerations
              .map((item) => item.billNumber)
              .join(' <br> ')
        ),
        fetchBillsForOperator: true,
        logic: 'RegenerateBillsWarning',
      };
      setDataSuspenseFallbackMessage(warningData);
    } else if (
      successfulBillRegenerations_length > 0 &&
      unSuccessfulBillRegenerations_length === 0
    ) {
      let successData = {
        open: true,
        title: t('LBLTitleRegenerateBillsSuccess'),
        severity: 'success',
        txtMessage: t('MSGRegenerateBillsBillsRegenerated'),
        fetchBillsForOperator: true,
        logic: 'RegenerateBillsSuccess',
      };
      setDataSuspenseFallbackMessage(successData);
    }
  };

  const ShowErrorResponseAfterRegeneratingBills = (error) => {
    setButtonRegenerateIncorrectBillsIsSubmitting(false);
    unselectAllRows();
    let txtMessage = t('MSGRegenerateBillsGlobalError');
    let errorDataTittle = t('LBLTitleRegenerateBillsError');
    if (
      error.response.data.error.errorCodeResponse === 'NO_INPUT_PROVIDED_ERROR'
    ) {
      txtMessage = t('MSGRegenerateBillsNoInputProvidedError');
      errorDataTittle = t('LBLTitleInvalidSelection');
    } else if (
      error.response.data.error.errorCodeResponse === 'INVALID_INPUT_ERROR'
    ) {
      txtMessage = t('MSGRegenerateBillsInvalidInputError');
      errorDataTittle = t('LBLTitleInvalidSelection');
    } else if (
      error.response.data.error.errorCodeResponse ===
      'BILLS_WITH_GENERATED_STATUS_ERROR'
    ) {
      txtMessage = t('MSGRegenerateBillsBillsWithGeneratedStatusError');
      errorDataTittle = t('LBLTitleInvalidSelection');
    } else if (
      error.response.data.error.errorCodeResponse === 'BILLING_UTILITY_ERROR'
    ) {
      txtMessage = t('MSGRegenerateBillsBillingUtilityError');
      errorDataTittle = t('LBLTitleRegenerateBillsError');
    } else {
      txtMessage = t('MSGRegenerateBillsGlobalError');
      errorDataTittle = t('LBLTitleRegenerateBillsError');
    }

    let errorData = {
      open: true,
      title: errorDataTittle,
      severity: 'warning',
      txtMessage: txtMessage,
      fetchBillsForOperator: true,
      logic: 'RegenerateBillsError',
    };
    setDataSuspenseFallbackMessage(errorData);
  };

  const regenerateIncorrectBills = (selected) => {
    if (token && user) {
      try {
        setLoading(true);
        setButtonRegenerateIncorrectBillsIsSubmitting(true);
        unselectAllRows();
        const billNumbers = selected.map((item) => item.billNumber);
        const payload = {
          billNumbers: billNumbers,
        };
        api.regenerateIncorrectBills(token, payload).then(
          (response) => {
            setLoading(false);
            showSuccessResponseAfterRegeneratingBills(response);
          },
          (error) => {
            setLoading(false);
            ShowErrorResponseAfterRegeneratingBills(error);
          }
        );
      } catch (exception) {
        setLoading(false);
        setButtonRegenerateIncorrectBillsIsSubmitting(false);
      }
    }
  };

  const fetchBillsForOperator = () => {
    if (token && user) {
      try {
        setLoading(true);
        unselectAllRows();
        setBTNSubmitIsSubmitting(true);
        const params = new URLSearchParams({
          startDate: startDate + ' 00:00',
          endDate: endDate + ' 23:59',
          billNumberExists: true,
          page: currentPage,
          size: pageSize,
          origin: BillingDataForOrigin.BILLS_PAGE,
        });
        api.fetchBills(token, params).then(
          (response) => {
            if (
              response.data.data == null ||
              response.data.data.response.billDetails === null ||
              response.data.data.response.billDetails.billCosts === null
            ) {
              setLoading(false);
              setBTNSubmitIsSubmitting(false);
              rows = [];
              setBills(rows);
            } else {
              setTotalRecords(response?.data?.data?.totalNumberOfRecords);
              setLoading(false);
              setBTNSubmitIsSubmitting(false);
              rows = response.data.data.response.billDetails.billCosts.map(
                (item) => ({
                  ...item,
                  fullName: `${item.endCustomerFirstName} ${item.endCustomerLastName}`,
                })
              );
              setBills(rows);
            }
          },
          () => {
            setLoading(false);
            setBTNSubmitIsSubmitting(false);
            rows = [];
            setBills(rows);
          }
        );
      } catch (exception) {
        setLoading(false);
        setBTNSubmitIsSubmitting(false);
        rows = [];
        setBills(rows);
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <LogoLoader loading={loading} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLBills')}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{ width: '100%' }}
        xl={3}
        py={1}
        display="flex"
        justifyContent="flex-end"
      >
        <Formik
          initialValues={dateFiltersInitialValues}
          validationSchema={dateFiltersValidationSchema}
          validateOnMount={true}
          onSubmit={handleSubmitGenerationDates}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form noValidate>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    label={t('LBLStartDate')}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.startDate}
                    onChange={(newValue) => {
                      handleStartDateChange(newValue, setFieldValue);
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        required
                        name="startDate"
                        margin="none"
                        autoComplete="off"
                        helperText={errors.startDate}
                        error={errors.startDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    label={t('LBLEndDate')}
                    value={values.endDate}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue, setFieldValue);
                    }}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        name="endDate"
                        margin="none"
                        autoComplete="off"
                        helperText={errors.endDate}
                        error={errors.endDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ px: 3, my: 4, borderRadius: 1 }}
                  disabled={errors.startDate || errors.endDate}
                  loading={BTNSubmitIsSubmitting}
                >
                  {t('BTNSubmit')}
                </LoadingButton>

                <Button
                  id="bill-factions-button"
                  aria-controls={open ? 'bill-factions-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant="contained"
                  disabled={selected.length == 0}
                >
                  {t('factions')}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'edit-selected',
                  }}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleSendPdfBillEmail(selected);
                      handleClose();
                    }}
                    disabled={
                      ButtonSendBillsIsSubmitting ||
                      selected.length == 0 ||
                      selected.some((row) => row.billingStatus !== 'GENERATED')
                    }
                  >
                    <Typography align="center" sx={{ width: '100%' }}>
                      {t('LBLButtonSendBills')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdfBills(selected);
                      handleClose();
                    }}
                  >
                    <Typography align="center" sx={{ width: '100%' }}>
                      {t('LBLButtonDownloadBills')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    style={{
                      display:
                        decodedToken?.resource_access?.admin_oli_move?.roles.some(
                          (role) => role === 'oli_move_admin'
                        )
                          ? true
                          : 'none',
                    }}
                    onClick={() => {
                      handleRegenerateBills(selected);
                      handleClose();
                    }}
                    disabled={
                      ButtonRegenerateIncorrectBillsIsSubmitting ||
                      selected.length == 0 ||
                      selected.length > 1 ||
                      selected.some((row) => row.billingStatus !== 'GENERATED')
                    }
                  >
                    <Typography align="center" sx={{ width: '100%' }}>
                      {t('LBLButtonRegenerateBills')}
                    </Typography>
                  </MenuItem>
                </Menu>

                <Suspense fallback={<div />}>
                  <p>
                    <Feedback
                      open={dataSuspenseFallbackMessage.open}
                      title={dataSuspenseFallbackMessage.title}
                      severity={dataSuspenseFallbackMessage.severity}
                      message={
                        <Typography
                          variant="subtitle1"
                          sx={{ width: 400, mx: 10 }}
                        >
                          {dataSuspenseFallbackMessage.txtMessage}
                        </Typography>
                      }
                      handleClose={() => {
                        if (dataSuspenseFallbackMessage.fetchBillsForOperator)
                          fetchBillsForOperator(pageSize, currentPage);
                        setDataSuspenseFallbackMessage(
                          initDataSuspenseFallbackMessage
                        );
                      }}
                    />
                  </p>
                </Suspense>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
      <Divider my={6} />
      <Paper>
        <>
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              '& .MuiTableCell-head:not(:nth-of-type(2)) button': {
                mx: 0,
              },
            }}
          >
            <DataTable
              data={renderData}
              columns={columns}
              options={{
                filter: false,
                search: false,
                selectableRows: 'multiple',
                rowsSelected: selectedRows,
                responsive: 'standard',
                onRowSelectionChange: (_props, allRowsSelected) => {
                  setSelectedRows(allRowsSelected.map(({ index }) => index));
                },
                textLabels: {
                  body: {
                    noMatch: t('LBLNoBills'),
                  },
                },
                currentPage: currentPage,
                totalRecords: totalRecords,
              }}
              getData={(seletedPageSize, currentPageVal) => {
                setCurrentPage(currentPageVal);
                setPageSize(seletedPageSize);
              }}
            />
          </Paper>
        </>
      </Paper>
    </React.Fragment>
  );
}
export default OperatorBills;